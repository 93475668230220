import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import * as api from "common/api";

import { Categories, Channel } from "common/types";
import { ClientRootState } from "webclient-store";
import { selectToken } from "pages/web-client/AccountModals/loginSlice";

type WebClientState = {
  currentVideo: Channel.Channel | Categories.CategoryItem | null;
  isLoading: boolean;
  isLoadingCategories: boolean;
  error: string | undefined;
  channels: Channel.Channel[];
  categories: Categories.ClientCategory[];
};

const initialState: WebClientState = {
  channels: [],
  currentVideo: null,
  isLoading: true,
  isLoadingCategories: false,
  error: undefined,
  categories: [],
};

export const fetchStations = createAsyncThunk(
  "web-client/fetch",
  async (_, { getState }) => {
    const state = getState() as ClientRootState;
    const token = selectToken(state) || api.CLIENT_TOKEN;
    let result = { coords: { latitude: 0, longitude: 0 } };
    try {
      result = await new Promise<GeolocationPosition>((res, rej) =>
        window.navigator.geolocation.getCurrentPosition(res, rej)
      );
    } catch (e) {
      console.log("Geolocation detection error:", e);
    }

    return api.getLiveStreams({
      latitude: result.coords.latitude,
      longitude: result.coords.longitude,
      token,
    });
  }
);

export const fetchCategories = createAsyncThunk(
  "web-client/fetch-categories",
  async (_, { getState }) => {
    const state = getState() as ClientRootState;
    const token = selectToken(state) || api.CLIENT_TOKEN;
    return api.getClientCategories({ token });
  }
);

const webClientSlice = createSlice({
  name: "web-client",
  initialState,
  reducers: {
    watchVideo(
      state,
      { payload }: { payload: Channel.Channel | Categories.CategoryItem }
    ) {
      state.currentVideo = payload;
    },
    stopVideo(state) {
      state.currentVideo = null;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(fetchStations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload.length !== 0) {
          // - web cams don't containe "listings"
          // - wrong live sources have empty "listings"
          state.channels = payload.filter(
            (ch) => !ch.listings || ch.listings?.length
          );
        }
      })
      .addCase(fetchStations.rejected, (state, { error }) => {
        state.isLoading = false;
        console.log("Station fetch is rejected", error);
        state.error = error.message;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.isLoadingCategories = true;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload: { data } }) => {
        state.isLoadingCategories = false;
        state.categories = data;
      })
      .addCase(fetchCategories.rejected, (state, { error }) => {
        state.isLoadingCategories = false;
        state.error = error.message;
      });
  },
});

export const { watchVideo, stopVideo } = webClientSlice.actions;

const sliceSelector = (state: ClientRootState) => state.webClient;

export const selectError = createSelector(
  sliceSelector,
  (state) => state.error
);

export const selectChannels = createSelector(
  sliceSelector,
  (state) => state.channels
);

export const selectIsLoading = createSelector(
  sliceSelector,
  (state) => state.isLoading
);

export const selectIsLoadingCategories = createSelector(
  sliceSelector,
  (state) => state.isLoadingCategories
);

export const selectCategories = createSelector(
  sliceSelector,
  (state) => state.categories
);

export const selectPlayerData = createSelector(sliceSelector, (state) => {
  const playerData = {
    hlsUrl: "",
    isLiveStream: false,
    poster: "",
    adTag: "",
  };

  if (state.currentVideo) {
    if ("playlistUrl" in state.currentVideo) {
      playerData.hlsUrl = state.currentVideo.playlistUrl;
      playerData.isLiveStream = true;
      playerData.poster = state.currentVideo.logoUrl;
      playerData.adTag = state.currentVideo.adTag;
    } else {
      playerData.hlsUrl = state.currentVideo.url;
      playerData.poster = state.currentVideo.thumbnailUrl;
    }
  }

  return playerData;
});

export const selectCurrentVideo = createSelector(
  sliceSelector,
  (state) => state.currentVideo
);

const themeSliceSelector = (state: ClientRootState) => state.theme;

export const selectAdTags = createSelector(themeSliceSelector, (state) => {
  return {
    midRollAdTag: state?.settings?.midRollAdTag,
    preRollAdTag: state?.settings?.preRollAdTag,
  };
});

export default webClientSlice.reducer;
